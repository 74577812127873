import type { ReactNode } from "react";
import type { Location, To } from "react-router-dom";
import type {
  AttachedFileFieldsFragment,
  CurrentAccessUserQuery,
  DocumentFieldsFragment,
  DocumentListResult,
  PhotosetPickerFieldsFragment
} from "~/types/api";
import { useMatches } from "@remix-run/react";
import Decimal from "decimal.js";
import _ from "lodash";
import numeral from "numeral";
import _pluralize from "pluralize";
import { useMemo } from "react";
import Link from "~/components/link";
import { formatDate } from "~/utils/dates";

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (route as any).data;
}

export const useOptionalUser = () => {
  const data = useMatchesData("root");
  return data?.user
    ? (data?.user as CurrentAccessUserQuery["currentAccessUser"])
    : null;
};

export const encodeRFC3986URIComponent = (str: string) => {
  return encodeURI(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
};

export const s3Link = (path: string, key: string) => {
  return `${path}${encodeURI(key.replace(/\?(\d+)$/, "?v=$1"))}`;
};

interface MoneyOptTypes {
  zeroes?: false;
  decorator?: true;
}

export const formatMoney = (
  value: string | number | null | Decimal | undefined,
  opts: MoneyOptTypes = {}
): string => {
  if (value === undefined || value === null) return "";
  const num =
    typeof value === "number"
      ? value
      : Decimal.isDecimal(value)
        ? value.toString()
        : parseFloat(value);
  if (num === 0 && opts.zeroes === false) {
    return "-";
  }

  return formatNumber(value, {
    format: opts.decorator ? "($0,0.00)" : "(0,0.00)",
    zeroes: opts.zeroes !== false
  });
};

interface NumberOptTypes {
  zeroes?: boolean;
  format?: string;
}
export const formatNumber = (
  value: string | number | null | Decimal | undefined,
  opts: NumberOptTypes = {}
): string => {
  if (value === undefined || value === null) return "";
  const v = typeof value === "string" ? parseFloat(value) : value;
  if ((!v || v === 0) && !opts.zeroes) {
    return "-";
  }
  return numeral(Decimal.isDecimal(v) ? v.toString() : v).format(
    opts.format || "0,0.0"
  );
};

export const getPrimaryFile = (
  document?:
    | DocumentFieldsFragment
    | { attachedFiles: AttachedFileFieldsFragment[] }
    | null
): AttachedFileFieldsFragment | undefined => {
  if (!document) return undefined;
  const attachedFiles = document.attachedFiles || [];
  const primary = attachedFiles.find((af) => af.primaryFile);
  if (
    !primary &&
    "sourceFile" in document &&
    document.__typename === "Document" &&
    document.sourceFile
  ) {
    return getPrimaryFile(document.sourceFile);
  }
  return primary;
};

export const getPhotosetTitle = (
  item: PhotosetPickerFieldsFragment,
  keyPhotos = false
) => {
  let result;

  if (!item.pfcs) {
    result = item.party;
    if (item.inspectionDate) {
      const formatted = formatDate(item.inspectionDate);
      result = `${result} - ${formatted}`;
    } else {
      result = `${result} - N/A`;
    }
    return result;
  }

  const date = formatDate(item.inspectionDate!);
  const day = item.day! < 10 ? `0${item.day}` : item.day;
  const expert = item.user!.login;

  result = `${date} ${expert}-${day} - ${item.user!.fullname}`;

  if (keyPhotos) {
    result = `${result} (${plural("key photo", item.keyPhotoCount, true)})`;
  }

  return result;
};

export const getDocumentTitle = (document: DocumentListResult) => {
  if (!document) {
    return "";
  }
  let output = [];
  const d = document;

  let title = d.description;
  if (!title) {
    if (document.mode === "File") {
      title = "N/A";
    } else {
      title = "";
    }
  }

  if (d.author && title.length) {
    output.push(`${d.author} - ${title}`);
  } else if (d.author) {
    output.push(d.author);
  } else {
    output.push(title);
  }

  if (d.date) {
    output.push(formatDate(d.date));
  } else if (d.receivedDate) {
    output.push(`RECD ${formatDate(d.receivedDate)}`);
  }

  output = _.reject(output, (item) => _.isUndefined(item) || _.isNull(item));

  return output.join(" ");
};

type Props = {
  word: string;
  count: number;
  include?: boolean;
  format?: string;
};

export const plural = (
  word: string,
  count: number,
  include?: boolean,
  opts: NumberOptTypes = {}
) => {
  const result = _pluralize(word, count);
  return include
    ? `${formatNumber(count, {
        format: opts.format ?? "0,0",
        zeroes: opts.zeroes ?? true
      })} ${result}`
    : result;
};

export default function Plural({
  word,
  count,
  include = true,
  format = "0,0"
}: Props) {
  return <span>{plural(word, count, include, { format })}</span>;
}

export const optionalLink = (content: ReactNode, url?: To | string | null) =>
  _.isObject(url) ? (
    <Link to={url}>{content}</Link>
  ) : url ? (
    <a href={url} target="_blank">
      {content}
    </a>
  ) : (
    <div>{content}</div>
  );

/**
 * Appends/deletes params on a react-router location object
 * @param location react-router Location object, usually from useLocation()
 * @param params Object with new keys
 * @returns string with new URL
 */
export const appendParams = (
  location: Location,
  params: Record<string, string | undefined>
) => {
  const searchParams = new URLSearchParams(location.search);
  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
  }
  return `${location.pathname}?${searchParams.toString()}`;
};

export const STRIPE_PUBLIC_KEY = import.meta.env.DEV
  ? "pk_test_40OivVIOyb5OpYs6vlrRmXawZ0WZlbEcH8K9GzcQsxmBnHtjJB8JFodvv12bbTpU4mFAQBqKJ6Bq8SOJAwX8bHujs00QXaSnhK5"
  : "pk_live_40OivVIOyb5OpYs6vlrRmGZqxhBVSd3LehSauvXsgIjJ9IWPNHN7ZRC2X4tiy3SvHv58lBRDYNotx5XbqPGhNX9Bt00iNJe3Lgy";

export const STRIPE_SECRET_KEY = import.meta.env.DEV
  ? "sk_DqDl6Fv0HetIDf8O8BjkuVph0V1VZ"
  : "sk_4GFW57fRVkDdCXsygZpx3M5bIbQi7";
